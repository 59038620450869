import React from "react"
import PropTypes from "prop-types"
import Logo from "../images/logo.svg"

import Header from "./header"
import "./default.scss"
import "./layout.scss"

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <div>
        <main>{children}</main>
        <footer>
          <img src={Logo} alt="logo" className="logo" />
          <span className="copyright">© Copyright 2020 Pisach</span>
          <span className="designed-by">
            designed by{" "}
            <a href="http://holy.gd/" rel="noopener noreferrer" target="_blank">
              høly
            </a>
          </span>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
