import React from "react"
import Logo from "../images/logo.svg"

const Header = ({ siteTitle }) => (
  <header>
    <img src={Logo} alt="logo" className="logo" />
    <nav className="nav">
      <ul>
        <li>
          <a
            onClick={() =>
              document
                .getElementById("about")
                .scrollIntoView({ behavior: "smooth" })
            }
          >
            About
          </a>
        </li>
        <li>
          <a
            onClick={() =>
              document
                .getElementById("global-partners")
                .scrollIntoView({ behavior: "smooth" })
            }
          >
            London Partners
          </a>
        </li>
        <li>
          <a
            onClick={() =>
              document
                .getElementById("what-we-do")
                .scrollIntoView({ behavior: "smooth" })
            }
          >
            What we do
          </a>
        </li>
      </ul>
    </nav>
  </header>
)

export default Header
